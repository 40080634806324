<template>
  <div class="main">
    <div class="title">开关控制</div>
    <!-- <div class="Colswitch">
      <div>智能电柜设备开关</div>
      <div>
        <el-radio-group v-model="radio" @change="ColSwitch()">
          <el-radio :label="0o0">开</el-radio>
          <el-radio :label="0o1">关</el-radio>
        </el-radio-group>
      </div>
    </div> -->
    <!-- 智能电柜各模块开关 -->
    <div class="Submodule">
      <div class="sub-title">智能电器柜各模块开关</div>
      <!-- <div class="table-main">
        <div v-for="(item, i) in MKData" :key="i" class="box">
          <p>
            <span
              :class="item.show ? 'arrow arrows' : 'arrow'"
              @click="moduleRequest(item.id, i, item.show)"
            ></span>
          
            <span class="title">{{ item.deviceName }}</span>
          </p>
          <div v-show="item.show" style="margin-top: 25px">
           
            <el-table
              v-show="listShow"
              :data="SubModuleList"
              border
              :header-cell-style="{ color: 'rgba(0, 0, 0, 0.85)' }"
              style="width: 100%; margin-top: 20px; color: rgba(0, 0, 0, 0.85)"
            >
              <el-table-column label="序号" width="55" align="center">
                <template slot-scope="scope">
                  {{ scope.$index + 1 }}
                </template>
              </el-table-column>
              <el-table-column
                prop="moduleName"
                label="模块名称"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="moduleStatus"
                label="开关状态"
                align="center"
              >
                <template slot-scope="scope">
                  <el-switch
                    class="demo"
                    v-model="scope.row.moduleStatus"
                    active-color="#13ce66"
                    inactive-color="#909399"
                    active-text="开"
                    inactive-text="关"
                    size="medium"
                    @change="switchChange(scope.row.id, scope.row.moduleStatus)"
                  >
                  </el-switch>
                </template>
              </el-table-column>
            </el-table>
            <div class="noMkinfo" v-show="!listShow">暂无模块信息</div>
          </div>
        </div>
      </div> -->

      <div class="table-main">
        <el-table
          :data="MKData"
          border
          :header-cell-style="{ color: 'rgba(0, 0, 0, 0.85)' }"
          style="width: 100%; margin-top: 20px; color: rgba(0, 0, 0, 0.85)"
        >
          <el-table-column label="序号" width="55" align="center">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column prop="deviceName" label="智能电器柜名称">
            <template slot-scope="scope">
              <p>
                <span
                  :class="scope.row.show ? 'arrow arrows' : 'arrow'"
                  @click="
                    moduleRequest(scope.row.id, scope.$index, scope.row.show)
                  "
                ></span>
                <span class="title">{{ scope.row.deviceName }}</span>
              </p>
              <div v-show="scope.row.show" style="margin-top: 25px">
                <el-table
                  :data="SubModuleList"
                  border
                  :header-cell-style="{ color: 'rgba(0, 0, 0, 0.85)' }"
                  style="
                    width: 100%;
                    margin-top: 20px;
                    color: rgba(0, 0, 0, 0.85);
                  "
                >
                  <el-table-column label="序号" width="55" align="center">
                    <template slot-scope="scope">
                      {{ scope.$index + 1 }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="moduleName"
                    label="模块名称"
                    align="center"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="moduleStatus"
                    label="开关状态"
                    align="center"
                  >
                    <template slot-scope="scope">
                      <el-switch
                        class="demo"
                        v-model="scope.row.moduleStatus"
                        active-color="#13ce66"
                        inactive-color="#909399"
                        active-text="开"
                        inactive-text="关"
                        size="medium"
                        @change="
                          switchChange(
                            scope.row.id,
                            scope.row.moduleStatus,
                            scope.row.deviceId,
                            scope.row.modbusNumber
                          )
                        "
                      >
                      </el-switch>
                    </template>
                  </el-table-column>
                </el-table>
                <!-- <div class="noMkinfo" v-show="!listShow">暂无模块信息</div> -->
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <div class="paging">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="100"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalPage"
          style="
            background-color: #fff;
            text-align: right;
            padding: 0px !important;
          "
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // 总开关
      radio: 1,
      // 各个模块开关
      SubModuleList: [],
      index: -1,
      //   是否显示模块
      showMK: false,
      // 模块是否有内容展示
      listShow: false,
      // 模块开关消息控制
      mouSwitch: "",
      currentPage: 1,
      pageSize: 10,
      totalPage: 10,
      tableData: [],
      MKData: [],
    };
  },
  mounted() {
    // var that = this;
    // this.connect();
    // that.getMessage("01");
    // this.setInter = setInterval(function () {
    //     that.getMessage('01')
    // }, 1000 * 5)
    this.listRequest();
    this.watchStorage();
  },
  methods: {
    // 总开关
    ColSwitch() {
      let a;
      if (this.radio == 0) {
        a = "00";
      } else {
        a = "01";
      }
      console.log(a);
      this.getMessage(a);
    },
    // 各个模块开关
    getSwitch(i, e) {
      if (e == 0) {
        this.mouSwitch = "00";
      } else if (e == 1) {
        this.mouSwitch = "01";
      }
      console.log(i, e, this.mouSwitch);
    },
    connect() {
      var ws = new WebSocket("ws://115.220.6.10:10900");
      ws.onopen = function () {
        var json = { name: "testWeb" };
        ws.send(JSON.stringify(json));
      };
      ws.onmessage = function (e) {
        console.log(e.data);
        console.log(JSON.parse(e.data));
      };
      ws.onerror = function () {
        console.log("发生异常了");
      };
    },
    getMessage(e) {
      var ws = new WebSocket("ws://115.220.6.10:10900");
      ws.onopen = function () {
        var json = { mac: "001122334455", type: "02", command: e };
        ws.send(JSON.stringify(json));
      };
      ws.onmessage = function (e) {
        console.log(e.data);
        console.log(JSON.parse(e.data));
      };
      ws.onerror = function () {
        console.log("发生异常了");
      };
    },
    // 监控项目是否改变
    watchStorage() {
      const that = this;
      window.addEventListener("setItemEvent", function (e) {
        // 监听setitem的 key ，执行对应的业务逻辑
        console.log(e.key, e.value);
        if (e.key === "projectID") {
          const newProjectId = e.value;
          that.listRequest(newProjectId);
        }
      });
    },
    // 请求设备列表接口
    listRequest(newProjectId) {
      var that = this;
      let arr = [];
      let projectID = localStorage.getItem("projectID");
      // console.log(newProjectId);
      if (newProjectId) {
        arr.push(newProjectId);
      } else {
        arr.push(projectID);
      }
      let param = {
        projectList: arr,
        pageNum: that.currentPage,
        pageSize: that.pageSize,
      };
      this.$post("/device/pageList", param)
        .then((res) => {
          if (res.code == 1) {
            this.tableData = res.data.list;
            this.totalPage = res.data.total;
            this.MKData = this.tableData.map((item) => {
              return { ...item, show: false };
            });
            console.log(this.MKData);
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {
          console.log("异常");
        });
    },
    // 请求模块接口
    moduleRequest(id, i, show) {
      this.MKData.forEach((element, index) => {
        if (i == index) {
          element.show = !element.show;
        } else {
          element.show = false;
        }
      });
      this.index = i;
      this.SubModuleList = [];
      this.showMK = true;
      let param = {
        deviceId: id,
        pageNum: this.currentPage,
        pageSize: this.pageSize,
      };
      this.$post("/module/pageList", param)
        .then((res) => {
          this.SubModuleList = res.data.list;

          if (this.SubModuleList.length > 0) {
            this.listShow = true;
          } else {
            this.listShow = false;
          }
          console.log(this.SubModuleList);
        })
        .catch(() => {
          console.log("异常");
        });
    },

    // 下发开关指令
    switchChange(id, status, deviceId, modbusNumber) {
      console.log(status);
      let switchAction;
      let projectID = localStorage.getItem("projectID");
      if (status) {
        switchAction = 1;
      } else {
        switchAction = 0;
      }
      console.log(id, switchAction);
      let param = {
        projectID: Number(projectID),
        deviceID: deviceId,
        moduleID: id,
        modbusNumber: modbusNumber,
        operateCode: 1,
        switchAction: switchAction,
      };
      console.log(param);
      this.$post("/instructionIssuance/sendInstructionIssuanceMessage", param)
        .then((res) => {
          if (res.code == 1) {
            this.$message.success("下发开关指令成功！");
          } else {
            this.$message.error("下发开关指令失败！");
          }
        })
        .catch(() => {
          console.log("异常");
        });
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.listRequest();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.listRequest();
    },
  },
};
</script>
<style scoped>
.demo >>> .el-switch__label {
  position: absolute;
  display: none;
  color: #fff;
}
.demo >>> .el-switch__label--right {
  z-index: 1;
  left: -3px;
}
.demo >>> .el-switch__label--left {
  z-index: 1;
  left: 19px;
}
.demo >>> .el-switch__label.is-active {
  display: block;
}
.demo >>> .el-switch .el-switch__core,
.el-switch .el-switch__label {
  width: 50px !important;
}
</style>
<style lang="scss" scoped>
.main {
  margin: 10px;

  // 总开关
  .Colswitch {
    margin: 20px 5px;
    height: 50px;
    line-height: 50px;
    padding: 0 20px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
  }
  // 箭头
  .arrow {
    display: inline-block;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 8px solid #909399;
    transition: transform 0.5s ease-in-out;
    margin-right: 5px;
  }
  .arrows {
    transform: rotate(90deg);
  }
  //  模块整体信息
  .table-main {
    border: 1px solid #ebeef5;
    border-bottom: none;
    margin: 10px 0;
    font-size: 16px;
    .box {
      border-bottom: 1px solid #ebeef5;
      padding: 20px;
      box-sizing: border-box;
    }

    .noMkinfo {
      margin: 20px 20px 0px;
      font-size: 14px;
      color: red;
    }
  }

  // 各个模块开关
  .Submodule {
    margin: 20px 5px;
    padding: 20px;
    background-color: #fff;

    .sub-switch {
      margin: 10px 20px;
      font-size: 15px;
    }
  }
}
</style>